import {FC} from 'react';
import {Box, Center, Text} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EntityHeader, TSvgName} from '@progress-fe/ui-kit';

import {EntityItem} from 'ui-kit';
import {TResultDetailsModel} from 'core/models';

interface IProps {
  selectedId: string | null;
  results: TResultDetailsModel[];
  onSelect: (resultId: string) => void;
}

const ResultsListFC: FC<IProps> = ({selectedId, results, onSelect}) => {
  return (
    <Box overflowY="hidden">
      <EntityHeader title="Результаты" />
      <Box overflowY="auto" h="calc(100% - 40px)">
        {results.map((el) => {
          return (
            <EntityItem
              key={el.id}
              name={el.namePropValue}
              icon={el.icon as TSvgName}
              isActive={selectedId === el.id}
              onClick={() => onSelect(el.id)}
            />
          );
        })}

        {results.length === 0 && (
          <Center pb="40px" height="100%">
            <Text>Нет результатов</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export const ResultsList = observer(ResultsListFC);

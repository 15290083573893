import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {JsonSchema} from 'core/models';

const ModelDetails = types
  .model('ElementModelJsonSchemas', {
    uuid: types.string,
    name: types.string,
    type: types.enumeration(Object.values(EElement)),
    jsonSchemas: types.optional(types.array(JsonSchema), [])
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TModelDetailsModel = Instance<typeof ModelDetails>;

export type TModelDetailsSnapshotIn = SnapshotIn<typeof ModelDetails>;

export {ModelDetails};

import {Edge, Node} from '@xyflow/react';
import {
  ERFElement,
  TRFEdgeDataConfig,
  TRFStructDataConfig,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS
} from '@progress-fe/rf-core';

export const STRUCTURE_DRAWING_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFStructDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000001', // V-100
        type: ERFElement.Separator2ph,
        data: {
          elementName: 'V-100',
          templateCode: 'default'
        },
        position: {x: 55, y: 340},
        connectable: true,
        deletable: true
      },
      {
        id: '0001-M', // 1
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '1',
          templateCode: ''
        },
        position: {x: 5, y: 398},
        connectable: true,
        deletable: true
      },
      {
        id: '0002-M', // 2
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '2',
          templateCode: ''
        },
        position: {x: 105, y: 180},
        connectable: true,
        deletable: true
      },
      {
        id: '0003-M', // 3
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '3',
          templateCode: ''
        },
        position: {x: 160, y: 550},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000002', // K-100
        type: ERFElement.Compressor,
        data: {
          elementName: 'K-100',
          templateCode: 'default'
        },
        position: {x: 147, y: 250},
        connectable: true,
        deletable: true
      },
      {
        id: '0004-M', // 4
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4',
          templateCode: ''
        },
        position: {x: 202, y: 150},
        connectable: true,
        deletable: true
      },
      {
        id: '0100-Q', // Q-100
        type: ERFElement.FlowEnergy,
        data: {
          elementName: 'Q-100',
          templateCode: ''
        },
        position: {x: 240, y: 350},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000003', // E-100
        type: ERFElement.Cooler,
        data: {
          elementName: 'E-100',
          templateCode: 'default'
        },
        position: {x: 250, y: 135},
        connectable: true,
        deletable: true
      },
      {
        id: '0101-Q', // Q-101
        type: ERFElement.FlowEnergy,
        data: {
          elementName: 'Q-101',
          templateCode: ''
        },
        position: {x: 430, y: 30},
        connectable: true,
        deletable: true
      },
      {
        id: '0005-M', // 5
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '5',
          templateCode: ''
        },
        position: {x: 325, y: 150},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        type: ERFElement.Valve,
        data: {
          elementName: 'VLV-100',
          templateCode: 'default'
        },
        position: {x: 404, y: 200},
        connectable: true,
        deletable: true
      },
      {
        id: '0006-M', // 6
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '6',
          templateCode: ''
        },
        position: {x: 480, y: 213},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000005', // V-101
        type: ERFElement.Separator3ph,
        data: {
          elementName: 'V-101',
          templateCode: 'default'
        },
        position: {x: 492, y: 300},
        connectable: true,
        deletable: true
      },
      {
        id: '0007-M', // 7
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '7',
          templateCode: ''
        },
        position: {x: 650, y: 180},
        connectable: true,
        deletable: true
      },
      {
        id: '0008-M', // 8
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '8',
          templateCode: ''
        },
        position: {x: 650, y: 307},
        connectable: true,
        deletable: true
      },
      {
        id: '0009-M', // 9
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '9',
          templateCode: ''
        },
        position: {x: 650, y: 460},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: '196C89E0-0000-0000-0000-000000000009',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // V-100
        target: '0002-M', // 2
        sourceHandle: 'source_material_gas_flow',
        targetHandle: 'target_material_1',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-100000000009',
        type: 'material',
        reconnectable: true,
        source: '0002-M', // 2
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        sourceHandle: 'source_material_1',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000008',
        type: 'material',
        reconnectable: true,
        source: '0001-M', // 1
        target: '196C89E0-0000-0000-0000-000000000001', // V-100
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000010',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // V-100
        target: '0003-M', // 3
        sourceHandle: 'source_material_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000011',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000002', // K-100
        target: '0004-M', // 4
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_1',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-100000000011',
        type: 'material',
        reconnectable: true,
        source: '0004-M', // 4
        target: '196C89E0-0000-0000-0000-000000000003', // E-100
        sourceHandle: 'source_material_1',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000006',
        type: 'energy',
        reconnectable: true,
        source: '0100-Q', // Q-100
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        targetHandle: 'target_energy_flow',
        ...RF_ENERGY_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000012',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000003', // E-100
        target: '0005-M', // 5
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_1',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-100000000012',
        type: 'material',
        reconnectable: true,
        source: '0005-M', // 5
        target: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        sourceHandle: 'source_material_1',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000007',
        type: 'energy',
        reconnectable: true,
        target: '0101-Q', // Q-101
        source: '196C89E0-0000-0000-0000-000000000003', // E-100
        sourceHandle: 'source_energy_flow',
        ...RF_ENERGY_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000013',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        target: '0006-M', // 6
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_1',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-100000000013',
        type: 'material',
        reconnectable: true,
        source: '0006-M', // 6
        target: '196C89E0-0000-0000-0000-000000000005', // V-101
        sourceHandle: 'source_material_1',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000014',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: '0007-M', // 7
        sourceHandle: 'source_material_gas_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000015',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: '0008-M', // 8
        sourceHandle: 'source_material_light_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000016',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: '0009-M', // 9
        sourceHandle: 'source_material_heavy_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    nodes: [
      {
        id: '704F784D-0000-0000-0000-000000000001', // COKER-100
        type: ERFElement.Coker,
        data: {
          elementName: 'COKER-100',
          templateCode: 'default'
        },
        position: {x: 320, y: 155},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-1', // Hidden for 1
        type: ERFElement.FlowMaterial,
        data: {
          isSourceHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 120, y: 286},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: '704F784D-0000-0000-0000-000000000002',
        type: 'material',
        reconnectable: true,
        source: 'HIDDEN-Flow-1', // HIDDEN
        target: '704F784D-0000-0000-0000-000000000001', // COKER-100
        targetHandle: 'XXX', //TODO: createPortCode('material', 'target', 1),
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      }
    ]
  }
];

import {FC, memo} from 'react';
import {Box, Center, Text} from '@chakra-ui/react';
import {EntityHeader, ISvgMenuItem, TSvgName} from '@progress-fe/ui-kit';

import {EntityItem} from 'ui-kit/headers';

interface IEntity {
  id: string;
  name: string;
}

interface IProps {
  title: string;
  entities: IEntity[];
  noEntitiesText: string;
  icon: TSvgName;
  selectedId: string | null;
  menuItems?: ISvgMenuItem<string>[];
  onCreateModel: (value: string) => void;
  onSelect: (id: string) => void;
}

const ProjectEntitiesListFC: FC<IProps> = ({
  entities,
  icon,
  selectedId,
  title,
  noEntitiesText,
  menuItems,
  onCreateModel,
  onSelect
}) => {
  return (
    <Box overflowY="hidden">
      <EntityHeader title={title} menuItems={menuItems} onSelectItem={onCreateModel} />
      <Box overflowY="auto" h="calc(100% - 40px)">
        {entities.map((item) => {
          return (
            <EntityItem
              key={item.id}
              name={item.name}
              icon={icon}
              isActive={selectedId === item.id}
              onClick={() => onSelect(item.id)}
            />
          );
        })}

        {entities.length === 0 && (
          <Center pb="40px" height="100%">
            <Text>{noEntitiesText}</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export const ProjectEntitiesList = memo(ProjectEntitiesListFC);

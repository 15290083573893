import {FC} from 'react';
import {Box, Center, Text} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useT} from '@progress-fe/core';
import {ElementIcon} from '@progress-fe/rf-core';
import {EntityHeader, ISvgMenuItem, Loader} from '@progress-fe/ui-kit';

import {EntityItem} from 'ui-kit';
import {TModelDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  models: TModelDetailsModel[];
  menuItems: ISvgMenuItem<string>[];
  onSelect: (modelUuid: string) => void;
  onCreate: (modelUuid: string) => Promise<void>;
}

const ModelsListFC: FC<IProps> = ({
  isLoading,
  models,
  selectedId,
  menuItems,
  onSelect,
  onCreate
}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden">
      <EntityHeader title={t('models.models')} menuItems={menuItems} onSelectItem={onCreate} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {models.map((m) => (
              <EntityItem
                key={m.uuid}
                name={m.name}
                elementIcon={ElementIcon[m.type]}
                isActive={selectedId === m.uuid}
                onClick={() => onSelect(m.uuid)}
              />
            ))}

            {models.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{t('models.noModels')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ModelsList = observer(ModelsListFC);

import {DragEvent, FC, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {EElement, EventEmitter} from '@progress-fe/core';
import {
  Edge,
  Node,
  Connection,
  addEdge,
  reconnectEdge,
  useStoreApi,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  ReactFlowInstance,
  OnSelectionChangeParams
} from '@xyflow/react';
import {
  RFRender,
  isEnergyPort,
  portsHaveSameFlowType,
  IRFMenuItem,
  TRFEdgeDataConfig,
  TRFStructDataConfig,
  RF_FIT_VIEW_MAX_ZOOM,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS,
  RF_DRAG_NODE_TYPE,
  RF_DRAG_TEMPLATE_CODE
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';
import {EProjectEntity} from 'core/enums';

interface IProps {
  width: number;
  height: number;
  menuItems: IRFMenuItem[];
  initialNodes: Node<TRFStructDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  selectedEntityId: string | null;
  selectedEntityType: EProjectEntity;
  onCreateElement: (type: EElement) => Promise<string | null>;
  onDeleteElement: (uuid: string) => Promise<boolean>;
  onConnectElements: (
    uuidA: string,
    portA: string,
    uuidB: string,
    portB: string
  ) => Promise<boolean>;
  onReconnectElements: (
    uuidA: string,
    portA: string,
    uuidB: string,
    portB: string
  ) => Promise<boolean>;
  onDeleteConnection: (
    uuidA: string,
    portA: string,
    uuidB: string,
    portB: string
  ) => Promise<boolean>;
}

const RFWorkZoneFC: FC<IProps> = ({
  width,
  height,
  menuItems,
  initialNodes,
  initialEdges,
  selectedEntityId,
  selectedEntityType
  /*onCreateElement,
  onDeleteElement,
  onConnectElements,
  onReconnectElements,
  onDeleteConnection*/
}) => {
  const pickedEntityId = useRef<string | null>(null);

  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFStructDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const {getState} = useStoreApi();
  const {addSelectedNodes, resetSelectedElements} = getState();

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialEdges, initialNodes, setEdges, setNodes]);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  // Pick specific node or edge
  useEffect(() => {
    const pickEntity = (entityId: string) => {
      pickedEntityId.current = entityId;

      const foundNode = nodes.find((n) => n.id === entityId);
      if (foundNode) {
        addSelectedNodes([foundNode.id]);
      }
    };

    EventEmitter.on('PickEntity', pickEntity);

    return () => {
      EventEmitter.off('PickEntity', pickEntity);
    };
  }, [addSelectedNodes, nodes]);

  // Select specific node or edge
  useEffect(() => {
    if (selectedEntityId && selectedEntityType === EProjectEntity.Element) {
      const foundNode = nodes.find((n) => n.id === selectedEntityId);
      if (foundNode) {
        addSelectedNodes([foundNode.id]);
      }
    } else {
      resetSelectedElements();
    }
    // FYI: Should be only 1 dep
    // eslint-disable-next-line
  }, [selectedEntityId]);

  const onCreateEdgeByConnection = useCallback(
    (connection: Connection) => {
      const isValid = !!connection.source && !!connection.target;
      const hasHandles = !!connection.sourceHandle && !!connection.targetHandle;
      const isLogicValid = portsHaveSameFlowType(connection.sourceHandle, connection.targetHandle);

      if (!isValid || !hasHandles || !isLogicValid) return null;
      const isEdgeEnergy = isEnergyPort(connection.sourceHandle);

      const nodeA = nodes.find((n) => n.id === connection.source);
      const nodeB = nodes.find((n) => n.id === connection.target);
      console.info('AB', nodeA, nodeB);

      const edge: Edge<TRFEdgeDataConfig> = {
        id: uuidv4(),
        ...connection,
        type: isEdgeEnergy ? 'energy' : 'material',
        ...(isEdgeEnergy ? RF_ENERGY_EDGE_PROPS : RF_MATERIAL_EDGE_PROPS)
      };

      return edge;
    },
    [nodes]
  );

  // Specific node or edge was selected
  const handleOnChangeSelection = useCallback((params: OnSelectionChangeParams) => {
    const selectedNodes: Node[] = params.nodes;
    const newSelectedId = selectedNodes.length > 0 ? selectedNodes[0].id : null;

    // Don't emit event when node was selected by the Pick button
    if (newSelectedId && newSelectedId !== pickedEntityId.current) {
      EventEmitter.emit('SelectEntity', newSelectedId);
    }

    // Just clear node which was selected by the Pick button
    if (newSelectedId && newSelectedId === pickedEntityId.current) {
      pickedEntityId.current = null;
    }
  }, []);

  const handleOnConnect = useCallback(
    (connection: Connection) => {
      setEdges((existingEdges) => {
        const edge = onCreateEdgeByConnection(connection);
        return !!edge ? addEdge(edge, existingEdges) : existingEdges;
      });
    },
    [setEdges, onCreateEdgeByConnection]
  );

  const handleOnReconnect = useCallback(
    (oldEdge: Edge<TRFEdgeDataConfig>, newConnection: Connection) => {
      setEdges((existingEdges) => {
        const edge = onCreateEdgeByConnection(newConnection);
        return !!edge ? reconnectEdge(oldEdge, newConnection, existingEdges) : existingEdges;
      });
    },
    [setEdges, onCreateEdgeByConnection]
  );

  const handleOnDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const type = event.dataTransfer.getData(RF_DRAG_NODE_TYPE);
      const templateCode = event.dataTransfer.getData(RF_DRAG_TEMPLATE_CODE);

      const position = instance?.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });

      // check if the dropped element is valid
      if (!position || !type) return;

      // TODO
      const newNode: Node<TRFStructDataConfig> = {
        id: uuidv4(),
        type,
        position,
        data: {elementName: type, templateCode: templateCode} // TODO: From be side
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [instance, setNodes]
  );

  useOnSelectionChange({onChange: handleOnChangeSelection});

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <RFMenu menuItems={menuItems} height={height} />
      <Box width="100%" height={height}>
        <RFRender
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={handleOnConnect}
          onReconnect={handleOnReconnect}
          onDrop={handleOnDrop}
        />
      </Box>
    </Grid>
  );
};

export const RFWorkZone = observer(RFWorkZoneFC);

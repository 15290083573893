import {FC, memo} from 'react';
import {Text} from '@chakra-ui/react';

import {TEditValueType} from '../../../../../interfaces';
import {InputNumber} from '../../../../inputs';

interface IProps {
  value: string;
  isDisabled: boolean;
  editType?: TEditValueType;
  onChangeValue: (value: string) => void;
}

const TableValueFC: FC<IProps> = ({value, isDisabled, editType, onChangeValue}) => {
  if (editType) {
    return (
      <InputNumber
        isFloat={editType === 'float'}
        min={0}
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        isOnChangeOnlyOnBlur
        disabled={isDisabled}
        value={value}
        onChange={(newValue) => onChangeValue(newValue || '')}
      />
    );
  }

  return (
    <Text
      fontSize="12px"
      color="bodyText"
      opacity={isDisabled ? '0.5' : '1'}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {value}
    </Text>
  );
};

export const TableValue = memo(TableValueFC);

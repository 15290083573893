import {memo, useCallback, useRef, useState} from 'react';
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMenu,
  useOutsideClick
} from '@chakra-ui/react';

import {Svg} from '../../helpers';
import {ISvgMenuItem} from '../../../interfaces';

interface IProps<T> {
  items: ISvgMenuItem<T>[];
  onSelect: (value: T) => void;
}

const SvgMenuFC = <T,>({items, onSelect}: IProps<T>) => {
  const [activeMenuItem, setActiveMenuItem] = useState<ISvgMenuItem<T> | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const {isOpen, onToggle, onClose} = useMenu({closeOnSelect: false, closeOnBlur: false});

  const handleToggle = useCallback(() => {
    setActiveMenuItem(null);
    onToggle();
  }, [onToggle]);

  const handleClose = useCallback(() => {
    setActiveMenuItem(null);
    onClose();
  }, [onClose]);

  useOutsideClick({ref: menuRef, handler: handleClose});

  return (
    <Box ref={menuRef}>
      <Menu offset={[0, 2]} isOpen={isOpen} variant="multi">
        <MenuButton
          size="xs"
          as={IconButton}
          variant="ghostTr"
          icon={<Svg name="Plus" />}
          onClick={handleToggle}
        />

        <MenuList boxShadow="shadow_2" zIndex={3}>
          {/* LEVEL 1 */}
          {items.map((item) => (
            <MenuItem
              key={`${item.name}`}
              position="relative"
              isDisabled={item.isDisabled}
              onClick={() => setActiveMenuItem(item)}
            >
              <Grid
                p="3px 4px 3px 12px"
                borderRadius="4px"
                alignItems="center"
                gridTemplateColumns="1fr 24px"
                bg={item.id === activeMenuItem?.id ? 'accentLight !important' : 'transparent'}
                color={item.id === activeMenuItem?.id ? 'bodyText !important' : 'bodyText'}
                _hover={{bg: 'accent', color: 'white'}}
              >
                <Box>{item.name}</Box>
                <Box>
                  {(item.items?.length || 0 > 0) && (
                    <Box transform="rotate(180deg)" color="inherit">
                      <Svg size="s9" name="Back" />
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* LEVEL 2 */}
              {item.id === activeMenuItem?.id && (
                <Box
                  p="2px"
                  top="-2px"
                  left="calc(100% + 2px)"
                  bg="buttonBg"
                  borderRadius="4px"
                  position="absolute"
                  boxShadow="shadow_2"
                  zIndex="1"
                >
                  {item.items?.map((subItem) => (
                    <MenuItem
                      key={`${subItem.name}`}
                      isDisabled={subItem.isDisabled}
                      onClick={(event) => {
                        event.stopPropagation();
                        onSelect(subItem.id);
                        handleClose();
                      }}
                    >
                      <Box p="3px 12px" borderRadius="4px" _hover={{bg: 'accent', color: 'white'}}>
                        {subItem.name}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export const SvgMenu = memo(SvgMenuFC);

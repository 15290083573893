import {FC, memo} from 'react';
import {Box} from '@chakra-ui/react';
import {EntityHeader} from '@progress-fe/ui-kit';

import {EntityItem} from 'ui-kit';

interface IProps {
  isSelected: boolean;
  onSelect: () => void;
}

const TasksListFC: FC<IProps> = ({isSelected, onSelect}) => {
  return (
    <Box overflowY="hidden">
      <EntityHeader title="Задача" />
      <Box overflowY="auto" h="calc(100% - 40px)">
        <EntityItem name="Расчёт" icon="Target" isActive={isSelected} onClick={onSelect} />
      </Box>
    </Box>
  );
};

export const TasksList = memo(TasksListFC);

import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EElement} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {EProjectEntity} from 'core/enums';

import {ElementList, ModelsList, ResultsList, Settings, TasksList} from './components';

const ProjectStructureFC: FC = () => {
  const {techProcessStore, catalogsStore} = useStore();
  const {uiState, projectModels, projectElements, projectResults} = techProcessStore;
  const {projectUuid, checkpointUuid} = techProcessStore;
  const {elementsCatalog, modelsCatalog} = catalogsStore;
  const {entityId, entityType} = uiState;

  const handleCreateElement = async (type: EElement) => {
    const uuid = await projectElements.create(type);
    if (!uuid) return;

    await projectElements.init(projectUuid, checkpointUuid);
    uiState.select(EProjectEntity.Element, uuid);
  };

  const handleCreateModel = async (modelUuid: string) => {
    const uuid = await projectModels.create(modelUuid);
    if (!uuid) return;

    await projectModels.init(projectUuid, checkpointUuid);
    uiState.select(EProjectEntity.Model, uuid);
  };

  return (
    <Grid gridTemplateRows={'32px 8px 3fr 8px 2fr 8px 1fr 8px 2fr'} h="var(--height-layout)">
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={entityType === EProjectEntity.Settings}
        onSelect={() => uiState.select(EProjectEntity.Settings)}
      />

      <FlexDivider />

      {/* PROJECT ELEMENTS */}
      <ElementList
        isLoading={projectElements.isLoading}
        elements={projectElements.elements}
        menuItems={elementsCatalog.svgMenuItems}
        selectedId={entityType === EProjectEntity.Element ? entityId : null}
        onSelect={(uuid) => uiState.select(EProjectEntity.Element, uuid)}
        onCreate={handleCreateElement}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS MODELS */}
      <ModelsList
        isLoading={projectModels.isLoading}
        models={projectModels.models}
        menuItems={modelsCatalog.svgMenuItems}
        selectedId={entityType === EProjectEntity.Model ? entityId : null}
        onSelect={(uuid) => uiState.select(EProjectEntity.Model, uuid)}
        onCreate={handleCreateModel}
      />

      <FlexDivider withDots />

      {/* PROJECT TASKS */}
      <TasksList
        isSelected={entityType === EProjectEntity.Task}
        onSelect={() => uiState.select(EProjectEntity.Task)}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS RESULTS */}
      <ResultsList
        results={projectResults.results}
        selectedId={entityType === EProjectEntity.Result ? entityId : null}
        onSelect={(resultId) => uiState.select(EProjectEntity.Result, resultId)}
      />
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);

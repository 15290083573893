import {observer} from 'mobx-react-lite';
import {ReactFlowProvider} from '@xyflow/react';
import {FC, useEffect, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box, Center, Flex, IconButton, Tab, TabList, Tabs} from '@chakra-ui/react';
import {i18n, useT} from '@progress-fe/core';
import {Svg} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {EProjectEntity} from 'core/enums';
import {RFWorkZone, RFGraphZone, RFResultsZone} from 'ui-kit';

const TABS: {index: number; name: string; type: EProjectEntity}[] = [
  {index: 0, name: i18n.t('rfZones.tabs.structure'), type: EProjectEntity.Settings},
  {index: 1, name: i18n.t('rfZones.tabs.calculation'), type: EProjectEntity.Task},
  {index: 2, name: i18n.t('rfZones.tabs.results'), type: EProjectEntity.Result}
];

const MIN_RF_WIDTH_PX = 470;
const MIN_RF_MESSAGE_WIDTH_PX = 160;

const ProjectZonesFC: FC = () => {
  const {techProcessStore, catalogsStore} = useStore();
  const {uiState, projectElements, projectResults} = techProcessStore;
  const {projectDrawing} = techProcessStore;
  const {structureDrawing, calculationDrawing} = projectDrawing;
  const {elementsCatalog} = catalogsStore;
  const {entityId, entityType} = uiState;

  const [tabIndex, setTabIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const {t} = useT();

  useEffect(() => {
    const activeTab = TABS.find((tab) => tab.type === entityType) || TABS[0];
    if (activeTab.index !== tabIndex) {
      setTabIndex(activeTab.index);
    }
  }, [entityType, tabIndex]);

  const handleTabChange = (index: number) => {
    const activeTab = TABS[index] || TABS[0];
    const newEntityId =
      activeTab.type === EProjectEntity.Result ? projectResults.results[0]?.id : undefined;

    uiState.select(activeTab.type, newEntityId);
  };

  return (
    <Box
      h="100%"
      bg="darkWhite"
      position={isExpanded ? 'absolute' : 'relative'}
      {...(isExpanded ? {top: 0, bottom: 0, left: 0, right: 0, zIndex: 10} : {})}
    >
      <AutoSizer disableWidth={false}>
        {({height, width}) => {
          if (width < MIN_RF_WIDTH_PX) {
            return (
              <Center
                bg="darkWhite"
                width={`${width}px`}
                height={`${height}px`}
                flexDirection="column"
              >
                {width >= MIN_RF_MESSAGE_WIDTH_PX && (
                  <Box p="0 8px" textAlign="center">
                    <Box fontWeight={700}>{t('rfZones.common.noSpace')}</Box>
                    <Box>{t('rfZones.common.changeWidth')}</Box>
                  </Box>
                )}
              </Center>
            );
          }

          return (
            <Box width={width}>
              <Tabs variant="solid-rounded" index={tabIndex} onChange={handleTabChange}>
                <Flex align="center" justify="center" p="12px 0 0 48px">
                  <TabList position="relative" zIndex={1}>
                    {TABS.map((tab) => (
                      <Tab key={tab.index}>{tab.name}</Tab>
                    ))}
                  </TabList>
                </Flex>
              </Tabs>

              <Box
                zIndex={1}
                top="12px"
                right="12px"
                position="absolute"
                borderRadius="4px"
                boxShadow="shadow_2"
              >
                <IconButton
                  size="sm"
                  aria-label=""
                  variant="ghostBg"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={<Svg name={isExpanded ? 'Collapse' : 'Expand'} />}
                />
              </Box>

              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                {/* PROJECT STRUCTURE */}
                {tabIndex === 0 && (
                  <ReactFlowProvider>
                    <RFWorkZone
                      width={width}
                      height={height}
                      selectedEntityId={entityId}
                      selectedEntityType={entityType}
                      initialNodes={structureDrawing.nodes}
                      initialEdges={structureDrawing.edges}
                      menuItems={elementsCatalog.rfMenuItems}
                      onCreateElement={projectElements.create}
                      onDeleteElement={projectElements.delete}
                      onConnectElements={projectElements.connect}
                      onReconnectElements={projectElements.reconnect}
                      onDeleteConnection={projectElements.deleteConnection}
                    />
                  </ReactFlowProvider>
                )}

                {/* PROJECT CALCULATION */}
                {tabIndex === 1 && (
                  <RFGraphZone
                    width={width}
                    height={height}
                    initialNodes={calculationDrawing.nodes}
                    initialEdges={calculationDrawing.edges}
                  />
                )}

                {/* PROJECT RESULTS */}
                {tabIndex === 2 && (
                  <RFResultsZone
                    height={height}
                    selectedEntityId={entityId}
                    resultsDetails={projectResults.results}
                  />
                )}
              </Box>
            </Box>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export const ProjectZones = observer(ProjectZonesFC);

import {FC, memo} from 'react';
import {FieldProps} from '@rjsf/utils';
import {Box} from '@chakra-ui/react';

import {DialogableContainer} from '../../containers';

import {GroupReactionTable, ReactionsTableHeader} from './components';
import {TReactionGroup, TReactionsTableFormData} from './ReactionsParametersTableJsField.types';

interface IProps extends FieldProps {
  formData: TReactionsTableFormData;
}

/**
 * Component renders "/schemas/jsf-reaction-parameters-table" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TReactionsTableFormData.
 */
const ReactionsParametersTableJsFieldFC: FC<IProps> = ({schema, formData, onChange}) => {
  const formValue = formData.fieldValues;

  const handleGroupChange = (reactionGroup: TReactionGroup, index: number) => {
    const newReactions = formValue.map((item, idx) => (idx === index ? {...reactionGroup} : item));

    onChange({...formData, formValue: newReactions});
  };

  return (
    <DialogableContainer title={schema.title || ''} dialogMinWidth="90%" floatMinWidth="40%">
      <Box w="100%" overflow="auto" border="1px solid" borderColor="border" borderRadius="4px">
        <ReactionsTableHeader fieldConfig={formData.fieldConfig} />
        {formValue.map((reaction, idx) => (
          <GroupReactionTable
            key={idx}
            isReadonly={!!schema.readOnly}
            reaction={reaction}
            fieldConfig={formData.fieldConfig}
            onChange={(group) => handleGroupChange(group, idx)}
          />
        ))}
      </Box>
    </DialogableContainer>
  );
};

export const ReactionsParametersTableJsField = memo(ReactionsParametersTableJsFieldFC);

import {Edge, Node} from '@xyflow/react';
import {EElement} from '@progress-fe/core';
import {ERFElement, RF_ELEMENT_EDGE_PROPS, TRFCalcDataConfig} from '@progress-fe/rf-core';

const DEFAULT_X = 230;
const DEFAULT_Y = 60;
const X_STEP = 184;
const Y_STEP = 60;

const COKER_DEFAULT_X = 230;
const COKER_DEFAULT_Y = 120;
const COKER_Y_STEP = 80;

export const CALCULATION_DRAWING_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFCalcDataConfig, ERFElement>[];
  edges: Edge[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000008', // 1
        type: ERFElement.Element,
        data: {
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '1',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000001', // V-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.TwoPhaseSeparatorElement,
          elementName: 'V-100',
          elementModelName: 'Сеп 2-фазный-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000009', // 2
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '2',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 2},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000006', // Q-100
        type: ERFElement.Element,
        data: {
          isSourceHandle: true,
          elementType: EElement.EnergyFlowElement,
          elementName: 'Q-100'
        },
        position: {x: DEFAULT_X - X_STEP, y: DEFAULT_Y + Y_STEP * 2},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000010', // 3
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '3',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X + X_STEP, y: DEFAULT_Y + Y_STEP * 2},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000002', // K-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.CompressorElement,
          elementName: 'К-100',
          elementModelName: 'Компрессор-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 3},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000011', // 4
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '4',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 4},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000003', // E-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.CoolerElement,
          elementName: 'Е-100',
          elementModelName: 'Охладитель-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 5},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000012', // 5
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '5',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 6},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000007', // Q-101
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.EnergyFlowElement,
          elementName: 'Q-101'
        },
        position: {x: DEFAULT_X + X_STEP, y: DEFAULT_Y + Y_STEP * 6},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.ValveElement,
          elementName: 'VLV-100',
          elementModelName: 'Клапан-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 7},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000013', // 6
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '6',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 8},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000005', // V-101
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.TwoPhaseSeparatorElement,
          elementName: 'V-101',
          elementModelName: 'Сеп 3-фазный-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 9},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000015', // 8
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '8',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 10},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000014', // 7
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '7',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X - X_STEP, y: DEFAULT_Y + Y_STEP * 10},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000016', // 9
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '9',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: DEFAULT_X + X_STEP, y: DEFAULT_Y + Y_STEP * 10},
        deletable: false
      }
    ],
    edges: [
      {
        id: 'c1',
        source: '196C89E0-0000-0000-0000-000000000008',
        target: '196C89E0-0000-0000-0000-000000000001',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c2',
        source: '196C89E0-0000-0000-0000-000000000001',
        target: '196C89E0-0000-0000-0000-000000000009',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c3',
        source: '196C89E0-0000-0000-0000-000000000001',
        target: '196C89E0-0000-0000-0000-000000000010',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c4',
        source: '196C89E0-0000-0000-0000-000000000006',
        target: '196C89E0-0000-0000-0000-000000000002',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c5',
        source: '196C89E0-0000-0000-0000-000000000009',
        target: '196C89E0-0000-0000-0000-000000000002',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c6',
        source: '196C89E0-0000-0000-0000-000000000002',
        target: '196C89E0-0000-0000-0000-000000000011',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c7',
        source: '196C89E0-0000-0000-0000-000000000011',
        target: '196C89E0-0000-0000-0000-000000000003',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c8',
        source: '196C89E0-0000-0000-0000-000000000003',
        target: '196C89E0-0000-0000-0000-000000000012',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c9',
        source: '196C89E0-0000-0000-0000-000000000003',
        target: '196C89E0-0000-0000-0000-000000000007',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c10',
        source: '196C89E0-0000-0000-0000-000000000012',
        target: '196C89E0-0000-0000-0000-000000000004',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c11',
        source: '196C89E0-0000-0000-0000-000000000004',
        target: '196C89E0-0000-0000-0000-000000000013',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c12',
        source: '196C89E0-0000-0000-0000-000000000013',
        target: '196C89E0-0000-0000-0000-000000000005',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c13',
        source: '196C89E0-0000-0000-0000-000000000005',
        target: '196C89E0-0000-0000-0000-000000000015',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c14',
        source: '196C89E0-0000-0000-0000-000000000005',
        target: '196C89E0-0000-0000-0000-000000000014',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      },
      {
        id: 'c15',
        source: '196C89E0-0000-0000-0000-000000000005',
        target: '196C89E0-0000-0000-0000-000000000016',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    nodes: [
      {
        id: '704F784D-0000-0000-0000-000000000002', // 1
        type: ERFElement.Element,
        data: {
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '1',
          elementModelName: 'Пенг-Робинсон-1'
        },
        position: {x: COKER_DEFAULT_X, y: COKER_DEFAULT_Y},
        deletable: false
      },
      {
        id: '704F784D-0000-0000-0000-000000000001', // COKER-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.CatCrackingElement,
          elementName: 'COKER-100',
          elementModelName: 'УЗК-1'
        },
        position: {x: COKER_DEFAULT_X, y: COKER_DEFAULT_Y + COKER_Y_STEP},
        deletable: false
      }
    ],
    edges: [
      {
        id: 'c1',
        source: '704F784D-0000-0000-0000-000000000002',
        target: '704F784D-0000-0000-0000-000000000001',
        deletable: false,
        ...RF_ELEMENT_EDGE_PROPS
      }
    ]
  }
];

import {cast, flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {ElementsOut, type TechProcessActionResult, TechProcessApi} from 'api';
import {ElementDetails, RequestModel, ResetModel, TElementDetailsModel} from 'core/models';
import {ELEMENTS_LIST} from 'core/mocks/elements.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      projectUuid: '',
      checkpointUuid: '',
      fetchRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      elements: types.optional(types.array(ElementDetails), [])
    })
  )
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      self.elements = cast([]);

      // TODO: Temp. Removal
      const mockElements = ELEMENTS_LIST.find((m) => m.projectId === projectUuid)?.items || [];
      if (mockElements.length) {
        self.elements = cast(mockElements);
        return;
      }

      const response: ElementsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.elements) {
        self.elements = cast(
          response.elements.map((el) => ({
            uuid: el.uuid,
            name: el.name,
            type: el.type as EElement
          }))
        );
      }
    }),
    create: flow(function* (elementType: EElement) {
      const response: TechProcessActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newElement: {
            type: elementType
          }
        }
      );
      return !self.actionRequest.isError ? response.data.uuid : null;
    }),
    delete: flow(function* (uuid: string) {
      console.info(uuid);
      return Promise.resolve(false);
    }),
    connect: flow(function* (uuidA: string, portA: string, uuidB: string, portB: string) {
      console.info(uuidA, portA, uuidB, portB);
      return Promise.resolve(false);
    }),
    reconnect: flow(function* (uuidA: string, portA: string, uuidB: string, portB: string) {
      console.info(uuidA, portA, uuidB, portB);
      return Promise.resolve(false);
    }),
    deleteConnection: flow(function* (uuidA: string, portA: string, uuidB: string, portB: string) {
      console.info(uuidA, portA, uuidB, portB);
      return Promise.resolve(false);
    })
  }))
  .actions((self) => ({
    hasElement(elementId: string): boolean {
      return self.elements.some((e) => e.uuid === elementId);
    },
    findElement(elementId: string): TElementDetailsModel | undefined {
      return self.elements.find((e) => e.uuid === elementId);
    },
    resetCalculatedFieldsOfElements(): void {
      self.elements.forEach((element) => {
        element.jsonSchemas.forEach((jsonSchema) => {
          jsonSchema.resetFormFields();
        });
      });
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    }
  }));

export type TProjectElementsModel = Instance<typeof ProjectElements>;

export {ProjectElements};

import {flow, types} from 'mobx-state-tree';
import {EventEmitter} from '@progress-fe/core';

import {EProjectEntity} from 'core/enums';
import {
  ProjectBase,
  TModelDetailsModel,
  TElementDetailsModel,
  TResultDetailsModel
} from 'core/models';

import {
  ProjectDrawing,
  ProjectModels,
  ProjectResults,
  ProjectElements,
  ProjectSettings,
  ProjectTask
} from './models';

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectDrawing: types.optional(ProjectDrawing, {}),
      projectResults: types.optional(ProjectResults, {})
    })
  )
  .actions((self) => ({
    _selectEntity(entityId: string): void {
      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EProjectEntity.Element, entityId);
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EProjectEntity.Model, entityId);
      } else if (self.projectResults.hasResult(entityId)) {
        self.uiState.select(EProjectEntity.Result, entityId);
      }
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectEntity', self._selectEntity);
    },
    _unsubscribe() {
      EventEmitter.off('SelectEntity', self._selectEntity);
    },
    uninitialize() {
      this._unsubscribe();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId);

      if (!!self.projectInfo) {
        const {uuid} = self.projectInfo;

        yield self.projectModels.init(uuid, self.checkpointUuid);
        yield self.projectElements.init(uuid, self.checkpointUuid);
        self.projectDrawing.fetch(projectId); // TODO
        self.projectResults.fetch(projectId); // TODO
        self.projectSettings.init(projectId); // TODO

        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get selectedElement(): TElementDetailsModel | null {
      if (self.uiState.entityType !== EProjectEntity.Element) return null;
      return self.projectElements.elements.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedModel(): TModelDetailsModel | null {
      if (self.uiState.entityType !== EProjectEntity.Model) return null;
      return self.projectModels.models.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedResult(): TResultDetailsModel | null {
      if (self.uiState.entityType !== EProjectEntity.Result) return null;
      return self.projectResults.results.find((el) => el.id === self.uiState.entityId) || null;
    }
  }));

export {TechProcessStore};

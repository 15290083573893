import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Text, Center} from '@chakra-ui/react';
import {EntityHeader, ISvgMenuItem, Loader} from '@progress-fe/ui-kit';
import {ElementByRFElement, ElementIcon, ERFElement} from '@progress-fe/rf-core';
import {EElement, useT} from '@progress-fe/core';

import {EntityItem} from 'ui-kit';
import {TElementDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  elements: TElementDetailsModel[];
  menuItems: ISvgMenuItem<string>[];
  onSelect: (elementId: string) => void;
  onCreate: (type: EElement) => Promise<void>;
}

const ElementsListFC: FC<IProps> = ({
  isLoading,
  selectedId,
  elements,
  menuItems,
  onSelect,
  onCreate
}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden">
      <EntityHeader
        menuItems={menuItems}
        title={t('elements.elements')}
        onSelectItem={async (typeStr) => {
          const type = ElementByRFElement[typeStr as ERFElement];
          if (type) await onCreate(type);
        }}
      />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {elements.map((el) => {
              return (
                <EntityItem
                  key={el.uuid}
                  name={el.name}
                  suffix={t(`enum.element.${el.type}`)}
                  elementIcon={ElementIcon[el.type]}
                  isActive={selectedId === el.uuid}
                  onClick={() => onSelect(el.uuid)}
                />
              );
            })}

            {elements.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{t('elements.noElements')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ElementList = observer(ElementsListFC);
